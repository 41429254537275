import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataGrid } from "@material-ui/data-grid";
import NumberCard from "./NumberCard";
import TabPanel from "./TabPanel";
import { v4 as uuid } from "uuid";
import Star from "@material-ui/icons/Star";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const STATES = {
  LAUNCH: "load",
  LOADING: "loading",
  SUCCESS: "save",
  ERROR: "report",
};

const RANKS = {
  ALL: "0",
  STAR3: "3",
  STAR4: "4",
  STAR5: "5",
};

const TYPES = {
  ALL: "0",
  CHARACTER: "1",
  WEAPON: "2",
};

const POOLS = {
  ALL: "0",
  PERMANENT: "200",
  NOVICE: "100",
  CHARACTER: "301",
  WEAPON: "302",
};

const columns = [
  { field: "date", headerName: "Date", flex: 1, type: "string" },
  {
    field: "rank",
    headerName: "Rank",
    flex: 1,
    type: "string",
    renderCell: (params) => (
      <Box display="flex">
        {[...Array(Number(params.value))].map((e) => {
          switch (params.value) {
            case "5":
              return <Star key={uuid()} style={{ color: "#bb6938" }} />;
            case "4":
              return <Star key={uuid()} style={{ color: "#a15bde" }} />;
            case "3":
              return <Star key={uuid()} style={{ color: "#8e8e8e" }} />;
            default:
              return "Unknown value";
          }
        })}
      </Box>
    ),
  },
  { field: "name", headerName: "Name", flex: 1, type: "string" },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    type: "string",
    renderCell: (params) => typeName(params.value),
  },
  {
    field: "pool",
    headerName: "Pool",
    flex: 1,
    type: "string",
    renderCell: (params) => poolName(params.value),
  },
];

const typeName = (type) => {
  switch (type) {
    case TYPES.WEAPON:
      return "Weapon";
    case TYPES.CHARACTER:
      return "Character";
    default:
      return "Unknown";
  }
};

const poolName = (pool) => {
  switch (pool) {
    case POOLS.PERMANENT:
      return "Permanent Wish";
    case POOLS.NOVICE:
      return "Novice Wish";
    case POOLS.CHARACTER:
      return "Character Event Wish";
    case POOLS.WEAPON:
      return "Weapon Event Wish";
    default:
      return "Unknown";
  }
};

const createUrl = (auth, pool, end_id) =>
  `/event/gacha_info/api/getGachaLog?size=20&gacha_type=${pool}&end_id=${end_id}&${auth}`;

export default function History() {
  const [historyData, setHistoryData] = useState([]);
  const [status, setStatus] = useState(STATES.LAUNCH);
  const [tabPool, setTabPool] = useState(0);
  const [selectRank, setSelectRank] = useState(RANKS.ALL);
  const [selectType, setSelectType] = useState(TYPES.ALL);
  const [selectPool, setSelectPool] = useState(POOLS.ALL);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);

  var loadedPoolCount = 0;

  const getHistoryData = async (auth, pool, end_id) => {
    const url = createUrl(auth, pool, end_id);
    const response = await fetch(url);
    console.log(response);
    const jsonData = await response.json();
    if (jsonData.data && jsonData.data.list && jsonData.data.list.length) {
      const loaded = jsonData.data.list.map((item) => {
        return {
          id: item.id,
          date: item.time,
          name: item.name,
          type: itemType(item.item_type),
          rank: item.rank_type,
          pool: pool,
        };
      });
      setHistoryData((historyData) => [...historyData, ...loaded]);
      if (loaded.length < 20) {
        console.log(pool + ": Finished loading");
        console.log(loadedPoolCount);
        if (loadedPoolCount >= 3) {
          setStatus(STATES.SUCCESS);
        }
        loadedPoolCount++;
      } else {
        getHistoryData(auth, pool, loaded[loaded.length - 1].id);
      }
    } else {
      console.log(pool + ": No data to load");
      console.log(loadedPoolCount);
      if (loadedPoolCount >= 3) {
        setStatus(STATES.SUCCESS);
      }
      loadedPoolCount++;
    }
  };

  const itemType = (type) => {
    switch (type) {
      case "Weapon":
      case "武器":
        return TYPES.WEAPON;
      case "Character":
      case "角色":
        return TYPES.CHARACTER;
      default:
        return TYPES.ALL;
    }
  };

  const statusMessage = () => {
    switch (status) {
      case STATES.LAUNCH:
        return "Welcome!";
      case STATES.LOADING:
        return "Loading page " + Math.ceil(historyData.length / 20);
      case STATES.SUCCESS:
        return "Success!";
      case STATES.ERROR:
        return "Oops ...";
      default:
        return "default";
    }
  };

  const totalCount = () => {
    return historyData.length;
  };

  const count = (rank) => {
    return historyData.filter((e) => e.rank === rank).length;
  };

  const average = (rank, pool) => {
    if (status !== STATES.SUCCESS) {
      return "---";
    }
    const data = historyData.filter((e) => e.pool === pool);
    const count = data.filter((e) => e.rank === rank).length;
    var pity = 0;
    while (pity < data.length && data[pity].rank !== rank) {
      pity++;
    }
    return ((data.length * 1.0 - pity) / count).toFixed(2);
  };

  const pity = (rank, pool) => {
    if (status !== STATES.SUCCESS) {
      return "---";
    }
    var data = historyData.filter((e) => e.pool === pool);
    var ret = 0;
    while (ret < data.length && data[ret].rank !== rank) {
      ret++;
    }
    return ret;
  };

  const buildFilterModel = (rank, type, pool) => {
    var items = [];
    if (rank !== RANKS.ALL) {
      items.push({
        columnField: "rank",
        operatorValue: "equals",
        value: rank,
      });
    }
    if (type !== TYPES.ALL) {
      items.push({
        columnField: "type",
        operatorValue: "equals",
        value: type,
      });
    }
    if (pool !== POOLS.ALL) {
      items.push({
        columnField: "pool",
        operatorValue: "equals",
        value: pool,
      });
      console.log(items);
    }
    setFilterModel({ items: items });
  };

  const loadHistory = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const re = /authkey_ver.*/g;
      const match = text.match(re);
      const auth = match[0];

      setHistoryData([]);
      setStatus(STATES.LOADING);

      getHistoryData(auth, POOLS.PERMANENT, 0);
      getHistoryData(auth, POOLS.NOVICE, 0);
      getHistoryData(auth, POOLS.CHARACTER, 0);
      getHistoryData(auth, POOLS.WEAPON, 0);
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <div>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={3}>
          <NumberCard
            title={statusMessage()}
            number={
              <div>
                {status === STATES.LAUNCH && (
                  <div>
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                    >
                      Load
                      {/* <input
                        type="file"
                        accept="text/plain"
                        hidden
                        onChange={(e) => loadHistory(e)}
                      /> */}
                    </Button>
                  </div>
                )}
                {status === STATES.LOADING && (
                  <Button
                    variant="outlined"
                    component="label"
                    color="default"
                    size="large"
                    fullWidth
                  >
                    Loading
                  </Button>
                )}
                {status === STATES.SUCCESS && (
                  <Button
                    variant="outlined"
                    component="label"
                    color="secondary"
                    size="large"
                    fullWidth
                  >
                    Save
                  </Button>
                )}
              </div>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <NumberCard title="Total" number={totalCount()} />
        </Grid>
        <Grid item xs={3}>
          <NumberCard title="5-Star" number={count("5")} />
        </Grid>
        <Grid item xs={3}>
          <NumberCard title="4-Star" number={count("4")} />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Box>
              <Tabs
                value={tabPool}
                onChange={(event, newValue) => setTabPool(newValue)}
              >
                <Tab label="Character Wish" />
                <Tab label="Weapon Wish" />
                <Tab label="Permanent Wish" />
              </Tabs>
              <TabPanel
                value={tabPool}
                index={0}
                pool={POOLS.CHARACTER}
                average={average}
                pity={pity}
              />
              <TabPanel
                value={tabPool}
                index={1}
                pool={POOLS.WEAPON}
                average={average}
                pity={pity}
              />
              <TabPanel
                value={tabPool}
                index={2}
                pool={POOLS.PERMANENT}
                average={average}
                pity={pity}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Box display="flex">
              <Box p={2}>
                <FormControl variant="outlined">
                  <InputLabel>Rank</InputLabel>
                  <Select
                    value={selectRank}
                    onChange={(event) => {
                      setSelectRank(event.target.value);
                      setSelectType(TYPES.ALL);
                      setSelectPool(POOLS.ALL);
                      buildFilterModel(
                        event.target.value,
                        TYPES.ALL,
                        POOLS.ALL
                      );
                    }}
                    label="Rank"
                    autoWidth
                  >
                    <MenuItem value={RANKS.ALL}>All</MenuItem>
                    <MenuItem value={RANKS.STAR5}>5-Star</MenuItem>
                    <MenuItem value={RANKS.STAR4}>4-Star</MenuItem>
                    <MenuItem value={RANKS.STAR3}>3-Star</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box p={2}>
                <FormControl variant="outlined">
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={selectType}
                    onChange={(event) => {
                      setSelectRank(RANKS.ALL);
                      setSelectType(event.target.value);
                      setSelectPool(POOLS.ALL);
                      buildFilterModel(
                        RANKS.ALL,
                        event.target.value,
                        POOLS.ALL
                      );
                    }}
                    label="Type"
                    autoWidth
                  >
                    <MenuItem value={TYPES.ALL}>All</MenuItem>
                    <MenuItem value={TYPES.CHARACTER}>Character</MenuItem>
                    <MenuItem value={TYPES.WEAPON}>Weapon</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box p={2}>
                <FormControl variant="outlined">
                  <InputLabel>Pool</InputLabel>
                  <Select
                    value={selectPool}
                    onChange={(event) => {
                      setSelectRank(RANKS.ALL);
                      setSelectType(TYPES.ALL);
                      setSelectPool(event.target.value);
                      buildFilterModel(
                        RANKS.ALL,
                        TYPES.ALL,
                        event.target.value
                      );
                    }}
                    label="Pool"
                    autoWidth
                  >
                    <MenuItem value={POOLS.ALL}>All</MenuItem>
                    <MenuItem value={POOLS.PERMANENT}>Permanent Wish</MenuItem>
                    <MenuItem value={POOLS.NOVICE}>Novice Wish</MenuItem>
                    <MenuItem value={POOLS.CHARACTER}>Character Wish</MenuItem>
                    <MenuItem value={POOLS.WEAPON}>Weapon Wish</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <DataGrid
              autoHeight
              pagination
              pageSize={20}
              rows={historyData}
              columns={columns}
              filterModel={filterModel}
              sortModel={[
                {
                  field: "date",
                  sort: "desc",
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Instruction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please find the game log file at [your_game_folder]/output_log.txt
          </DialogContentText>
          <Button
            variant="outlined"
            component="label"
            color="primary"
            size="large"
            fullWidth
          >
            Find log file
            <input
              type="file"
              accept="text/plain"
              hidden
              onChange={(e) => {
                setDialogOpen(false);
                loadHistory(e);
              }}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
