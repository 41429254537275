import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountIcon from "@material-ui/icons/AccountBox";
import ListIcon from "@material-ui/icons/List";
import {
  Email,
  History,
  Timeline,
  ExposurePlus1,
  Language,
} from "@material-ui/icons";

export default function Navigation({ classes }) {
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <List>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Genshin Impact" />
        </ListItem>
      </List>
      <Divider className={classes.divider} />
      <List>
        <ListItem button>
          <ListItemIcon>
            <History className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="History" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Timeline className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Analysis" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ExposurePlus1 className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Simulator" />
        </ListItem>
      </List>
      <Divider className={classes.divider} />
      <List>
        <ListSubheader inset />
        <ListItem button>
          <ListItemIcon>
            <AccountIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Sign in" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Language className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Language" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Email className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Contact us" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ListIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Version" />
        </ListItem>
      </List>
    </Drawer>
  );
}
