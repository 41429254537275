import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#f7f9fc",
    },
    navigation: {
      background: "#233043",
      icon: "#868c96",
      text: "#ced0d3",
      divider: "#868c96",
    },
  },
});

export default theme;
