import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import NumberCard from "./NumberCard";

export default function TabPanel(props) {
  const { value, index, pool, average, pity } = props;

  return (
    <div hidden={value !== index}>
      <Box p={2}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={6}>
            <NumberCard
              title="Average number of pulls per 5-Star"
              number={average("5", pool)}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberCard
              title="Average number of pulls per 4-Star"
              number={average("4", pool)}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberCard title="5-Star pity count" number={pity("5", pool)} />
          </Grid>
          <Grid item xs={6}>
            <NumberCard title="4-Star pity count" number={pity("4", pool)} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
