import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import History from "./components/History";
import Copyright from "./components/Copyright";
import Navigation from "./components/Navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerPaper: {
    width: 240,
    background: theme.palette.navigation.background,
    color: theme.palette.navigation.text,
  },
  icon: {
    color: theme.palette.navigation.icon,
  },
  divider: {
    background: theme.palette.navigation.divider,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Navigation classes={classes} />

      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <History />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
